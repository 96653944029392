<template>
  <div class="flex flex-col">

    <div class="container flex-1 text-center text-white">
      <div class="md:w-2/3 lg:w-1/2 mx-auto">
        <the-title>
          Pandemic panda
        </the-title>

        <div class="mb-8">
          <p>
            is an online drinking game for all you rascals sitting there with deep shadows under your eyes as your face
            has
            not seen any kind of light source other than your screen for weeks. Pandemic Panda is a game for the time we
            all
            have to sit in self-isolation.
          </p>

          <p>
            In the current situation it is more than important to stay at home. But what we don't want to miss out
            though
            are social contacts and - much more important - the social awkwardness that comes with it!
          </p>

          <p>
            That's why we created an online drinking game where you can virtually get cringy AF interacting with other
            human
            beings while drowning your frustration in beer (or any other alcoholic beverage of your choice).
          </p>
        </div>

        <nuxt-link to="/introduction" class="btn">
          Start game
        </nuxt-link>
      </div>
    </div>


    <the-footer></the-footer>

  </div>

</template>

<script>
  export default {

  }

</script>

<style>

</style>
